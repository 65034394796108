export const organizRouter = [
    {
        path: '/',
        redirect: 'apply/list'
    },
    {
        path: 'apply/list',
        meta: {
            title: '自助建站申请',
            needLogin: true // 是否需要登录才能访问
        },
        component: () => import('../views/organiz/page/apply/list'),
    },
    {
        path: 'apply/publish',
        meta: {
            title: '选择模版',
            needLogin: true // 是否需要登录才能访问
        },
        component: () => import('../views/organiz/page/apply/publish'),
    }
]

export default organizRouter