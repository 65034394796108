/**
 * 线上环境
 */
export const ONLINEHOST = 'http://zzjzpre.emof.cn'

/**
 * 测试环境
 */
// export const QAHOST = 'http://192.168.2.123:8190'
export const QAHOST = 'http://zzjzpre.emof.cn'


export const baseURL = process.env.NODE_ENV === 'production' ? ONLINEHOST : QAHOST


//文件地址
export const filePath = baseURL
//文件上传地址
export const fileUpUrl = baseURL + '/api/Index/uploadfile'
/*
* 缓存字段
* */
export const localAnStore = [
    {
        key: 'user',
        type: 'localStorage'
    },
    {
        key: 'emofUserTokenSafer',
        type: 'cookie'
    }
]
