import {ONLINEHOST, QAHOST} from '../config'

const requestUrl = process.env.NODE_ENV === 'production' ? ONLINEHOST : QAHOST
/**
 * 获取Token
 */
export const getToken = () => {
    let user = window.localStorage.getItem('user')
    return user ? JSON.parse(user).token : '';
}

/*
* 百度编辑器配置
* */
export const bdEditConfig = () => {
    return {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: 320,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        // serverUrl: requestUrl + '/editor/controller.php',
        serverUrl: requestUrl + '/editor/controller.php',
        // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
        UEDITOR_HOME_URL: '/UEditor/'
    }
}
//跳转外链-首页
export const web_emof_home = 'http://www.zhichenghui.org.cn'
//跳转外链-机构设置
export const web_emof_setting = 'http://fw.zhichenghui.org.cn/'
//跳转外链-消息中心
export const web_emof_news = 'http://fw.zhichenghui.org.cn/'
//客服电话
export const service_phone = '400-999-6541'

/*
* 转换地址
* */
export const getDistrict = (address, district_id) => {
    let find0 = address.find(item => item.value === Number(district_id[0]));
    let find1 = find0.children.find(item => item.value === Number(district_id[1]));
    let find2 = find1.children.find(item => item.value === Number(district_id[2]));
    return [find0.label, find1.label, find2.label]
}

/*
* 数据过滤全部
* */
export const formatHtml = (str) => {
    const pattern = /<(\S*?)[^>]*>.*?|<.*? > /ig
    return str.replace(pattern, '').replace(/&nbsp;/ig, "")
}

export const getQueryString = (name)=>{
    const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    const r = window.location.search.substr(1).match(reg);
    if(r!==null)return  unescape(r[2]); return null;
}
import CryptoJS from "crypto-js";
export const md5 = (value)=>{
    return CryptoJS.MD5(value).toString()
}

/*
* 数据过滤全部
* */
export const optionList = (state, arr) => {
    if (state) {
        return arr
    } else {
        return arr.slice(1)
    }
}

/*
* value转换为label
* */
export const filesCN = (val, arr) => {
    let _str = arr.filter(item => val === item.value)
    return _str[0].label
}

/*
* value转换为label
* */
export const colorCN = (val, arr) => {
    let _str = arr.filter(item => val === item.value)
    return _str[0].color
}

/*
* value转换为label
* */
export const textCN = (val, arr) => {
    let _str = arr.filter(item => val === item.value)
    return _str[0].text
}

/*
* 时间转换
* */
export const zhDate = (date, type) => {
    let now = new Date(date)
    let year = now.getFullYear()
    let month = now.getMonth() + 1
    let day = now.getDate()
    let hour = now.getHours()
    let minute = now.getMinutes()
    let second = now.getSeconds()
    if (type === 1) {
        return year + "-" + getzf(month) + "-" + getzf(day) + "  " + getzf(hour) + ":" + getzf(minute)
    } else if (type === 2) {
        return year + "-" + getzf(month) + "-" + getzf(day) + "  " + getzf(hour) + ":" + getzf(minute) + ":" + getzf(second)
    } else if (type === 3) {
        return getzf(hour) + ":" + getzf(minute)
    } else if (type === 4) {
        return year
    } else {
        return year + "-" + getzf(month) + "-" + getzf(day)
    }
}

function getzf(num) {
    if (parseInt(num) < 10) {
        num = '0' + num
    }
    return num
}


export const zhRmb = (num) => {
    if (Number(num) > 10000) {
        return (Number(num) / 10000).toFixed(2) + '万元'
    } else {
        return num + '元'
    }
}

export const getUrlBase64=(url)=> {
    return new Promise(resolve => {
        let canvas = document.createElement('canvas')
        let ctx = canvas.getContext('2d')
        let img = new Image()
        img.crossOrigin = 'anonymous' //允许跨域
        img.src = url
        img.onload = function() {
            canvas.height = 300
            canvas.width = 300
            ctx.drawImage(img, 0, 0, 300, 300)
            let dataURL = canvas.toDataURL('image/png')
            canvas = null
            resolve(dataURL)
        }
    })
}

export const setPath =(title,path)=>{
    return [
        {
            title,
            path
        }
    ]
}