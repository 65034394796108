import Vue from 'vue'
import VueRouter from 'vue-router'
import organizRouter from "./organizRouter";

Vue.use(VueRouter)

const routes = [
    {
        path: '*',
        component: () => import('../components/404'),
        meta: {
            title: '404',
            needLogin: false // 是否需要登录才能访问
        }
    },
    {
        path: '/',
        redirect: () => {
            return '/organiz'
        }
    },
    {
        path: '/login',
        component: () =>
            import ('../views/login'),
        meta: {
            title: '登录',
            isLogin: true // 是否需要登录才能访问
        }
    },
    {
        path: '/tiaoz',
        component: () =>
            import ('../views/tiaoz'),
        meta: {
            title: '登录',
            isLogin: false // 是否需要登录才能访问
        }
    },
    {
        path: '/organiz',
        component: () =>
            import ('../views/organiz/home'),
        children: organizRouter,
    }
]

const router = new VueRouter({
    routes,
    base: process.env.BASE_URL,
    mode: 'history',
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {
    let user = JSON.parse(window.localStorage.getItem("user"));
    if (to.meta.isLogin) {
        window.localStorage.clear()
        next()
    } else {
        if ((to.meta.needLogin && user) || !to.meta.needLogin) {
            next()
        } else {
            next({
                path: '/login',
                params: to.params,
                query: to.query
            })
        }
    }
});

export default router
