import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      emofUserTokenSafer: '',
      user: '',
  },
  mutations: {
      increment(state) {
          // 变更状态
          state.emofUserTokenSafer = ''
          state.user = ''
      }
  },
  actions: {
      LogOut(context) {
          context.commit('increment')
      }
  },
  modules: {
  }
})
